<template>
  <div style='height: 100px'>
    <el-select
      v-model='currentData'
      filterable
      remote
      reserve-keyword
      :multiple='multiple'
      clearable
      placeholder='请输入：昵称和手机号'
      :remote-method='remoteMethod'
      :loading='loading'>
      <el-option
        v-for='item in options'
        :key='item.userid'
        :label='item.name'
        :value='item'>
        <span style='float: left'>{{ item.name }} {{JSON.stringify(currentData)}}</span>
        <span style='float: right'>{{ item.mobile }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'UserSelect',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      options: [],
      list: [],
      loading: false,
      multiple: true,
      leader: []
    }
  },
  // watch:{
  //   leader:{
  //     handler(val){
  //       console.log('leader',val)
  //       this.$emit()
  //     }
  //   }
  // },
  computed: {
    currentData:{
      get() {
        console.log(this.data)
        return [...this.data]
      },
      set(val) {
        console.log(val)
        this.$emit('update:data', val)
      }
    }
    // leader: {
    //   get() {
    //     return this.leaderId
    //   },
    //   set(val) {
    //     this.$emit('update:leaderId', val)
    //     this.changeLeader(val)
    //   }
    // }
  },
  mounted() {
  },
  methods: {
    async remoteMethod(query) {
      this.options = []
      if (query !== '') {
        this.loading = true
        await this.getOptions(query)
        this.loading = false
      } else {
        this.options = []
      }
    },
    async getOptions(query) {
      if (query != '') {
        this.options = await this.$api.searchUserDing(query)
        console.log('options',this.options)
        // Object.keys(list).forEach((value) => {
        //   let item = list[value]
        //   this.options.push({
        //     value: `${item.userid}`,
        //     label: `${item.name}`,
        //     mobile: `${item.mobile.substr(0, 3)}****${item.mobile.substr(7)}`
        //   })
        // })
      }

    },

  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <el-drawer :show-close='false' :withHeader='false' size='40%' :before-close='handleCloses'
               :append-to-body="true" :loading='drawerLoading' :visible='drawerRoute'>
      <div class='drawerTitle'>
        <el-input width='80' style='width: 80px' v-if='nameEdit' v-model='routeTemp.name'></el-input>
        <span v-else>
              {{ routeTemp.name }}
            </span>
        <i @click='clickEdit' :class="nameEdit?'el-icon-check':'el-icon-edit'"></i>
      </div>
      <div class='line'></div>
<!--      <div style='line-height: 20px;height:100px;border: #00feff 1px dashed'>-->
<!--        {{routeTemp}}-->
<!--      </div>-->
      <div class='drawer-content'>
        <div v-for='(item,index) in routerConditions' :key='index'>
          <el-card class='box-card' shadow='none'>
            <div slot='header' class='clearfix'>
              <span>条件组</span>
              <el-button style='float: right; padding: 3px 0' type='text' @click='deleteBtn(index)'>
                <i class='el-icon-delete'></i>
              </el-button>
            </div>
            <el-form label-position='left' label-width='120px' size='medium'>
              <el-form-item label='发起人' v-if="item.checkRoute.includes('dept_condition')">
                <el-input @focus='clickCheckRoute(item)' v-model='item.echoData'>
                </el-input>
              </el-form-item>
              <el-form-item label='且' v-show='item.checkRoute.length===2'></el-form-item>
              <el-form-item label='报销金额（元）' v-if="isShow(item)">
                <el-select size='mini' v-model='item.rangeValue.type' placeholder='请选择'>
                  <el-option
                      v-for='item in rangeOptions'
                      :key='item.value'
                      :label='item.title'
                      :value='item.value'>
                  </el-option>
                </el-select>
                <el-input v-model='item.rangeValue.value' size='mini' style='width: 130px;margin-left: 10px'></el-input>
                元
              </el-form-item>
              <el-form-item>
                <el-button size='medium' @click='clickRoute(item,index)'><i class='el-icon-plus'></i>添加条件
                </el-button>
                <span style='color: #8c939d'>  还有{{ 2 - item.checkRoute.length }}个条件可用</span>
              </el-form-item>
            </el-form>
          </el-card>
          <div style='margin: 10px 0;' v-show='index!=conditions.length-1'>或</div>
        </div>
        <el-button style='margin-top: 20px' size='medium' type='primary' @click='addRouteGroup'><i
            class='el-icon-plus'></i>添加条件组
        </el-button>
        <div class='footer'>
          <div>
            <el-button type='primary' size='medium' @click='saveRoute'>保存</el-button>
            <el-button size='medium'>取消</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
        :destroy-on-close='true'
        title='选择发起人'
        :visible.sync='dialogVisible'
        width='60%'
        :before-close='handleDiaLogClose'>
      <div class='notify-dept'>
        <div class='notify'>
          <DingTalkDeptSelect :checkStrictly="true" ref='refDngTalkDept' @check='getDeptMembers'/>
        </div>
        <div class='all_box'>
          <el-checkbox-group class='member-list' v-model='checkRouteData.checkedMembers'
                             @change='handleCheckedCitiesChange'>
            <el-checkbox v-for='(member,index) in members' :label='member' :key='member.userid+index+Math.random()'
                         size='large'
                         class='checkItem' border>
              <span>{{ member.name }} <span>{{ $utils.encryptMobile(member.mobile) }}</span></span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <br/>
      <div class='selected'>
        <hr/>
        <h1>已选内容</h1>
        <div class='selectItem'>
          <div
              class="tags"
              v-for='(item,index) in checkRouteData.checkDepts'
              :key='item.name+Math.random()'
          >
            {{ item.name }}
            <i class="el-icon-close" @click.self='closeDepts(index)'></i>
          </div>
          <div
              class="tags users"
              v-for='(item,index) in checkRouteData.checkedMembers'
              :key="item.name+'keyId'+Math.random()"
              @close='closeUser(index)'

          >
            {{ item.name }}
            <i class="el-icon-close" @click.self='closeUser(index)'></i></div>
        </div>
      </div>
      <div class='buttonBox'>
        <el-button size='medium' @click='confirmBtn' type='primary'>确认</el-button>
        <el-button size='medium' @click='dialogVisible=false'>取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title='选择条件'
        :visible.sync='dialogRoute'
        width='20%'
        :before-close='handleDiaLogClose'>
      请选择用来区分审批流程的条件字段 ，已选{{ checkRouteData.checkRoute?.length }}个<br/>
      <br/>
      <el-checkbox-group v-model='checkRouteData.checkRoute' @change="checkRoute">
        <el-checkbox label='dept_condition'>发起人</el-checkbox>
        <el-checkbox label='range_condition'>报销金额（元）</el-checkbox>
      </el-checkbox-group>
      <br/>
      <el-button size='mini' @click='confirmRouteBtn' type='primary'>确认</el-button>
      <el-button size='mini' @click='dialogRoute=false'>取消</el-button>
    </el-dialog>

  </div>
</template>

<script>
import DingTalkDeptSelect from '@/pages/oaManage/DingTalkDeptSelect'
import _ from 'lodash'

export default {
  name: 'SettingRouteDrawer',
  components: { DingTalkDeptSelect },

  data() {
    return {
      drawerLoading: false,
      nameEdit: false,

      dialogRoute: false,
      dialogVisible: false,
      members: [],
      dialogData: {
        isIndeterminate: true,
        checkAll: false,
        loadingNotify: false,
        showTaskList: false
      },
      rangeOptions: [
        { value: 'lowerBound', title: '小于' },
        { value: 'upperBound', title: '大于' },
        { value: 'boundEqual', title: '等于' },
        { value: 'lowerBoundEqual', title: '小于等于' },
        { value: 'upperBoundEqual', title: '大于等于' }
      ],
      checkRouteData: {},
      routerConditions: {},
      timer: null
    }
  },
  watch: {
    conditions(val) {
      this.routerConditions = val
    }
  },
  props: {
    drawerRoute: {
      type: Boolean
    },
    routeTemp: {
      type: Object
    },
    conditions: {
      type: Array,
      default() {
        return [
          {
            checkRoute: ['dept_condition'],
            rangeValue: {
              type: '',
              value: ''
            },
            echoData: '',
            checkDepts: [],
            checkedMembers: []
          }
        ]
      }
    }
  },
  methods: {
    isShow(item) {
      return item.checkRoute.includes('range_condition') && item?.rangeValue?.type != undefined
    },
    handleCloses() {
      // this.routeTemp = {
      this.$emit('update:drawerRoute',false)
    },
    clickEdit() {
      this.nameEdit = !this.nameEdit
    },
    checkRoute() {
      if (this.checkRouteData.checkRoute.includes('range_condition')) {
        this.$set(this.checkRouteData, 'rangeValue', {
          type: '',
          value: ''
        })
      }
    }
    ,
    clickCheckRoute(item) {
      this.checkRouteData = {}
      this.checkRouteData = item
      this.dialogVisible = true
    },
    clickRoute(item) {
      this.checkRouteData = item
      // this.checkRouteIndex = index
      this.dialogRoute = true
    },

    handleDiaLogClose() {
      this.dialogVisible = false
    },
    // 节流
    debounce(fn, delay) {
      let _t = this
      return (function(...args) {
        if (_t.timer) {
          clearTimeout(_t.timer)
        }
        _t.timer = setTimeout(() => {
          fn.call(this, args)
        }, delay)
      })()
    },
    closeDepts(index) {
      let checkDepts = this.checkRouteData?.checkDepts
      if (checkDepts) {
        checkDepts.splice(index, 1)
        this.debounce(this.fnName, 1000)
      }
    }
    ,
    fnName() {
      let checker = this.checkRouteData?.checkDepts.map((i) => {
        return i.dpt_id
      })
      this.$refs.refDngTalkDept.setCheckedKeys(checker)
    },
    closeUser(index) {
      let checkedMembers = this.checkRouteData?.checkedMembers
      if (checkedMembers) {
        checkedMembers.splice(index, 1)
      }
    }
    ,
    confirmBtn() {
      let data = [...this.checkRouteData.checkDepts, ...this.checkRouteData.checkedMembers]
      let map = data.map((i) => {
        return i.name
      })
      this.checkRouteData.echoData = ''
      this.checkRouteData.echoData = String(map)
      this.dialogVisible = false
    },

    confirmRouteBtn() {
      this.dialogRoute = false
    },

    addRouteGroup() {
      let tep = {
        checkRoute: ['dept_condition'],
        rangeValue: {
          type: '',
          value: ''
        },
        echoData: '',
        checkDepts: [],
        checkedMembers: []
      }
      this.routerConditions.push(tep)
    }   ,
    deleteBtn(index) {
      this.routerConditions.splice(index, 1)
    } ,
    saveRoute() {
      let conditions = []
      this.routerConditions.forEach((item) => {
        let dept = item.checkDepts.map((i) => {
          return {
            type: 'dept',
            value: i.dpt_id,
            name: i.name
          }
        })

        let user = item.checkedMembers.map((i) => {
          return {
            type: 'user',
            value: i.userid,
            name: i.name
          }
        })


        let range = {}
        if (item.rangeValue?.value && item.rangeValue?.value != '') {
          range = {
            type: 'range_condition',
            paramLabel: '报销金额（元）',
            isEmpty: false,
            [item.rangeValue.type]: item.rangeValue.value
          }
        }
        let newArray = []
        let conds = [...dept, ...user]
        if (conds?.length != 0) {
          let data = {
            type: 'dept_condition',
            paramLabel: '发起人',
            isEmpty: false,
            conds: conds
          }
          newArray.push(data)
        }
        if (range?.type) {
          newArray.push(range)
        }
        conditions.push(newArray)
      })
      if (_.flattenDeep(conditions).length === 0) {
        this.$notify.error('请至少选择一个条件')
        return false
      } else {
        let conditionNodes = {
          name: this.routeTemp.name,
          type: 'condition',
          properties: {
            conditions: conditions,
            childNode: this.routeTemp.properties?.childNode || {}
          }
        }
        this.$emit('update:routeTemp', conditionNodes)
        this.$emit('update:drawerRoute', false)
        this.$forceUpdate()
      }
    }
    ,
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.dialogData.checkAll = checkedCount === this.members.length
      this.dialogData.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    }
    ,
    // 成员选择模块
    getDeptMembers(data, checkedObj) {
      let depts = checkedObj.checkedNodes
      this.checkRouteData.checkDepts = depts
      this.defaultCheckBox()
      depts.forEach((item) => {
        this.loadMembers({ dpt_id: item.dpt_id })
      })
    }
    ,
    defaultCheckBox() {
      this.dialogData.checkAll = false
      // this.checkedMembers = []
      this.members = []
      this.dialogData.isIndeterminate = true
    }
    ,

    async loadMembers(searchCondition) {
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      list.forEach((item) => {
        let index = this.members.findIndex((value) => value.userid === item.user_id)
        if (index === -1) this.members.push(item)
      })
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.buttonBox {
  margin: 0 auto;
  text-align: center;

}

.selected {
  min-height: 100px;
  overflow: scroll;

  .selectItem {
    height: 120px;
  }
}

.drawerTitle {
  height: 60px;
  display: flex;
  line-height: 60px;
  padding: 0 20px;

  > span {
    font-size: 20px;
  }

  > i {
    margin-left: 10px;
    line-height: 60px;
    font-size: 20px;
  }
}

.notify-dept {
  width: 100%;

  display: flex;

  .notify {
    width: 50%;
    max-height: 500px;
    overflow: scroll;
  }

  .all_box {
    width: 50%;
    margin-left: 10px;
    border: 1px solid #F4F9FE;
    max-height: 500px;
    overflow: scroll;

    > * {
      padding: 20px;
    }

    .member-list {
      float: left;

      .checkItem {
        width: 160px;
        float: left;
        //margin: 0 !important;
        margin: 4px 10px;
      }
    }
  }
}


.line {
  border-top: 1px solid #EBEEF5;
  margin: 10px 0;
}
.box-card {
  overflow: scroll;
}

.footer {
  height: 6%;
  border-top: 1px solid #F2F6FC;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;

  > div {
    padding: 0 10px;
    float: right;
    margin-top: 2%;
    //padding: 10px;
  }
}

.drawer-content {
  padding: 0 20px;
}

.tags {
  display: flex;
  float: left;
  border: 1px solid rgb(233, 233, 235);
  background-color: rgb(244, 244, 245);
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;

  > i {
    margin-left: 4px;
  }

  > i:hover {
    color: red;
  }
}

.users {
  border: 1px solid rgb(217, 236, 255);
  background-color: rgb(236, 245, 255);
  color: rgb(64, 158, 255);
}
</style>

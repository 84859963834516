<template>
  <div>
    <div class='time-box'>
      <div class='time-arrow' v-if="type==='route'">
        <div class='time-add'>
          <el-popover placement='right' width='250' trigger='click'>
            <el-icon slot='reference' class='el-icon-plus'></el-icon>
            <el-button
              @click="addType('Approve',-1)">
              审批人
            </el-button>
            <el-button
              @click="addType('Cc',-1)">
              抄送人
            </el-button>
          </el-popover>
        </div>
        <div class='time-arrow-line'></div>
      </div>
      <div :key='key' v-for='(i, key) in nodeArr'>
        <div :class='`time-start ${isApprove(i.tpl_type)?"time-approve":"time-cc"}`'
             @click.stop='timeBoxClick(i,key)'>
          <div class='time-title'>
            {{ isApprove(i.tpl_type) ? '审批人' : '抄送人' }}
            <el-icon :key='key' @click.native.stop='minusBtn(key)'
                     class='el-icon-close time-close'></el-icon>
          </div>
          <div class='tile-content'>
            <span>{{ member_type_map[i.member_type] }}</span>
            <span v-if='i.members&&i.members.length>0'>：{{ memberNames(i.members ?? []) }}</span>
            <span style='float: right;color: #8c939d'>> </span>
          </div>
        </div>
        <div class='time-arrow'>
          <div class='time-add'>
            <el-popover placement='right' width='250' trigger='click'>
              <el-icon slot='reference' class='el-icon-plus'></el-icon>
              <el-button
                @click="addType('Approve',key)">
                审批人
              </el-button>
              <el-button
                @click="addType('Cc',key)">
                抄送人
              </el-button>
            </el-popover>
          </div>
          <div class='time-arrow-line'></div>
        </div>
        <!-- end-->
        <div v-if='key===nodeArr.length-1' class='time-arrow-end-text'>
          流程结束
        </div>
        <div v-else class='triangle'></div>
      </div>
    </div>
    <!--    设置审批人-->
    <el-drawer :modal='false' :title="drawerTemp?.tpl_type === 'Approve' ? '设置审批人' : '设置抄送人'"
               :visible.sync='drawer' :before-close='handleCloses'>
      <div class='drawer-content'>
        <div>
          <!--          成员类型选择-->
          <el-radio-group v-model='drawerTemp.member_type' @change='drawerSaved=false'>
            <el-radio style='display: block; margin: 10px' v-for='(item, index) in member_type_map'
                      :key='item'
                      :label='index'>{{ item }}
            </el-radio>
          </el-radio-group>
          <div style='padding: 10px'>
            <UserSelect
              v-show="drawerTemp.member_type==='fix'"
              style='display: inline-block; width: 200px'
              @selected='handleMLeaderSelect'
            />
          </div>
        </div>
        <div style='border: #000c17 1px dashed;padding: 10px;border-radius: 5px' v-if="drawerTemp.member_type==='fix'">
         <span style='font-weight: bold;font-size: 1.1em;'>
            成员({{ drawerTemp.members.length }})：
         </span>
          <div>
            <el-tag
              style='margin:10px 10px 0 0'
              v-for='(tag,index) in drawerTemp.members'
              @close='tagChange(index)'
              effect='plain'
              size='small'
              closable
              :key='index'
              :type="['success','info','primary'][0]">
              {{ tag.name }}
            </el-tag>
          </div>

        </div>
        <div style='padding: 20px;text-align: left'>
          <el-button type='primary' size='small' @click='subCommit'>确认</el-button>
          <el-button size='small' @click='drawer=false'>关闭</el-button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import UserSelect from '@/pages/commerce/components/UserSelect'
import ccSelect from '@/pages/oaManage/UserSelect'
import _ from 'lodash'

export default {
  name: 'SettingPanel',
  components: { UserSelect, ccSelect },
  props: {
    data: {
      type: [Object, Array]
    },
    type: {
      type: String
    }
  },
  computed: {
    nodeArr: {
      get() {
        let arr = []
        this.getChildNodeArr(this.data, arr)
        return arr
      }
      // eslint-disable-next-line no-unused-vars
      // set(val) {
      //   // this.$emit('update:data', val)
      //   this.callback()
      // }
    }
  },
  data() {
    return {
      member_type_map: {
        custom: '发起人自选',
        supervisor: '直属主管',
        director: '部门主管',
        fix: '指定成员'
      },
      drawerTemp: {},
      currentIndex: null,
      drawer: false,
      drawerSaved: false

    }
  },
  methods: {
    //递归将childNode装完数组
    getChildNodeArr(nodeObj, arr) {
      if (nodeObj && nodeObj.childNode) {
        let orgNode = { ...nodeObj.childNode }
        delete orgNode.childNode
        arr.push(orgNode)
        if (nodeObj.childNode.childNode) {
          return this.getChildNodeArr(nodeObj.childNode, arr)
        }
      } else {
        return arr
      }
    },
    getNodeObj(arr, nodeObj, childNodeKey) {
      if (arr.length > 0) {
        nodeObj[childNodeKey] = Object.assign({}, arr.splice(0, 1)[0])
      }
      if (arr.length > 0) {
        return this.getNodeObj(arr, nodeObj.childNode, childNodeKey)
      } else
        return nodeObj
    },
    getNodeObjByArr(arr) {
      let nodeObj = {}
      this.getNodeObj(arr, nodeObj, 'childNode')
      return nodeObj.childNode
    },
    isApprove(i) {
      return i === 'Approve'
    },
    timeBoxClick(row, index) {
      this.drawerTemp = { ...row }
      this.currentIndex = index
      this.drawer = true
      this.drawerSaved = true
    },
    //删除节点
    minusBtn(index) {
      this.nodeArr.splice(index, 1)
      this.callback()
      // this.$emit('update:childNode', this.getNodeObjByArr(this.nodeArr))
      this.$forceUpdate()
    },

    getNodeObjByIndex(i, obj) {
      if (i === 0) {
        return obj.childNode
      } else {
        i--
        return this.getNodeObjByIndex(i, obj)
      }
    },
    setNodeChildByIndex(i, orgNodeObj, appendNode) {
      if (i === 0) {
        orgNodeObj.childNode = appendNode
        return orgNodeObj.childNode
      } else {
        i--
        return this.setNodeChildByIndex(i, orgNodeObj.childNode, appendNode)
      }
    },
    // eslint-disable-next-line no-unused-vars
    addType(type, index) {
      // ##################### 方案三 #####################
      let appendNode = {
        member_type: 'custom',
        members: [],
        nodeId: Math.random(),
        tpl_type: type
      }
      if (this.nodeArr.length > 0) {
        this.nodeArr.splice(index + 1, 0, appendNode)
      } else {
        this.nodeArr.push(appendNode)
      }
      this.callback()

      // this.$emit('update:childNode', this.getNodeObjByArr(this.nodeArr))
      this.$forceUpdate()
    },
    callback() {
      let data = { ...this.data }
      data.childNode = this.getNodeObjByArr(this.nodeArr)
      //把数据传递给父组件
      this.$emit('update:data', data)
    },
    addRouteType(type, index, item, length) {
      if (index === 0) {
        item.childNode = {
          member_type: 'fix',
          members: [],
          nodeId: Math.random(),
          tpl_type: type,
          childNode: item.childNode
        }
        this.formatChildNode(item)
        this.$forceUpdate()
      } else {
        let forData = item
        let newObject = []
        const fn = function(data) {
          newObject.push(data)
          forData = forData.childNode
        }
        for (let i = 0; i <= length; i++) {
          if (forData?.childNode) {
            if (i === index) {
              forData.childNode = {
                member_type: 'fix',
                members: [],
                nodeId: Math.random(),
                tpl_type: type,
                childNode: { ...forData.childNode }
              }
            }
            fn(forData.childNode)
          }
        }
        item = newObject[0]
        this.formatChildNode(item)
        this.$forceUpdate()
      }
    },

    memberNames(i) {
      let names = i.map((j) => {
        return j.name
      })
      return String(names)
    },
    tagChange(i) {
      this.drawerTemp.members.splice(i, 1)
    },

    handleMLeaderSelect(item) {
      this.drawerSaved = false
      if (!this.drawerTemp.members) {
        this.drawerTemp.members = []
      }
      let pushObj = { name: item.label, userid: item.value }
      let index = _.findIndex(this.drawerTemp.members, pushObj)
      if (index < 0) {
        this.drawerTemp.members.push({ name: item.label, userid: item.value })
      }
    },
    subCommit() {
      if (this.drawerTemp.member_type !== 'fix') {
        this.drawerTemp.members = []
      }
      //保存
      if (this.nodeArr[this.currentIndex]) {
        this.nodeArr[this.currentIndex] = { ...this.drawerTemp }
      }
      this.drawerSaved = true
      this.drawer = false
      this.callback()

    },
    handleCloses() {
      if (!this.drawerSaved) {
        this.$confirm('关闭窗口是否先保存数据', '提示', {
          confirmButtonText: '保存并关闭',
          cancelButtonText: '直接关闭',
          type: 'warning'
        }).then(async () => {
          this.subCommit()
          this.drawer = false
        }).catch(() => {
          this.drawer = false
        })
      } else {
        this.drawer = false
      }

    }
  },
  mounted() {

  }
}
</script>

<style lang='scss' scoped>

.router-box {
  width: 100%;
  display: flex;
  justify-content: center;


  .col-box {
    width: 220px;
    //border: 1px solid red;
    float: left;
    position: relative;

    .col-box:before {
      content: '';
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #e4e7ed;
    }

    .top-cover-line {
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #e4e7ed;
      top: -2px;
      left: -1px;
    }

    .top-vertical-line {
      width: 2px;
      height: 50px;
      background-color: #e4e7ed;
      position: relative;
      left: calc(50% - 2px)

    }

    .condition-node {
      min-height: 140px;
      width: 200px;
      margin: 0 auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-flex: 1;
    }
  }

  .col-first {
    .top-cover-line {
      width: 50%;
      left: calc(50% - 2px);
    }
  }

  .col-final {
    .top-cover-line {
      width: 50%;
    }
  }
}


.time-start {
  margin: 0 auto;
  width: 200px;
  height: 80px;
  //border: 1px solid silver;
  border-top: none;
  display: block;
  clear: both;

  .time-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    padding-left: 20px;
    color: white;
    background-color: rgb(63, 76, 108);
    z-index: 2;
    position: relative;
  }

  .tile-content {
    padding-left: 20px;
    border: 1px solid silver;
    border-top: none;
    border-radius: 4px;
    margin-top: -4px;
    height: 50px;
    line-height: 50px;
  }
}

.time-approve {
  cursor: pointer;

  .time-title {
    background-color: rgb(245, 154, 35);
  }
}

.time-cc {
  cursor: pointer;

  .time-title {
    background-color: #409eff;
  }
}

.time-close {
  font-size: 18px;
  float: right;
  cursor: pointer;
  margin-right: 8px;
}


.drawer-content {
  padding: 0 20px;
}

.box-card {
  overflow: scroll;
}

.time-arrow {
  position: relative;
  height: auto;

  .time-arrow-line {
    height: 80px;
    border-left: 2px solid #e4e7ed;
    width: 2px;
    margin: 0 auto;
  }

  .triangle {
    width: 0;
    height: 0;
    border: 15px solid;
    margin: 0 auto;
    border-color: #e4e7ed rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
  }

  .routerRight {
    border-right: 1px solid #e4e7ed;
    float: right;
  }

  .time-add {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #409eff;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: calc(50% - 15px);
    top: 24px;
    cursor: pointer;
    z-index: 99;
  }

  .time-add:hover {
    box-shadow: 1px 1px #c0c4cc;
    -webkit-transition-duration: 0.4s;
    transform: perspective(1px) translateZ(0);
  }
}


.time-arrow-end-text {
  text-align: center;
  margin-top: 10px;
  color: #909399;
}


.drawer-content {
  padding: 0 20px;
}

.time-route {
  border: 1px solid #e4e7ed;
  min-height: 100px;
  max-height: 200px;


  > * {
    padding: 10px;
  }

  .time-title {
    color: #2d815d;
  }


}

.time-arrow {
  position: relative;
  height: auto;

  .time-arrow-line {
    height: 80px;
    border-left: 2px solid #e4e7ed;
    width: 2px;
    margin: 0 auto;
  }

  .triangle {
    width: 0;
    height: 0;
    border: 15px solid;
    margin: 0 auto;
    border-color: #e4e7ed rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
  }

  .routerRight {
    border-right: 1px solid #e4e7ed;
    float: right;
  }

  .time-add {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #409eff;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: calc(50% - 15px);
    top: 24px;
    cursor: pointer;
    z-index: 99;
  }

  .time-add:hover {
    box-shadow: 1px 1px #c0c4cc;
    -webkit-transition-duration: 0.4s;
    transform: perspective(1px) translateZ(0);
  }
}

.time-arrow-end {
  .triangle {
    width: 11px;
    height: 11px;
    margin: 0 auto;
    border-radius: 50%;
    border: 0 solid #e4e7ed;
    background-color: #e4e7ed;
  }

  .time-arrow-end-text {
    text-align: center;
    margin-top: 10px;
    color: #909399;
  }
}

.triangle {
  width: 0;
  height: 0;
  border: 15px solid;
  margin: 0 auto;
  border-color: #E4E7ED rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
}

</style>
